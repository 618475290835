import { RedocStandalone } from 'redoc';

function App() {
  return (
    <RedocStandalone
      // specUrl={require("./apiSpecsYaml/TruKno-TruKno-Api-V1-resolved.yaml")}
      spec={require("./apiSpecsYaml/Api-V1.json")}
      options={{
        // nativeScrollbars: true,
        // theme: {colors: { primary: { main: '#dd5522' } }, },
      }}
    />
  );
}

export default App;
